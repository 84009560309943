import * as React from 'react';

import {
  SubProperty,
  useGetSubPropertiesLazyQuery,
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery
} from '../../generated/graphql';
import { useDomain } from '../../utils';
import { useParams } from 'react-router-dom';

const useSubProperty = () => {
  const [subProperties, setSubProperties] = React.useState<SubProperty[]>([]);

  const { id } = useParams();

  const [subPropertiesQuery, { data: spData, loading: spLoading }] =
    useGetSubPropertiesLazyQuery();

  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        propertyID
      }
    });

    subPropertiesQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        propertyID
      }
    });

    const spIdList = propertyMeta?.getMeta?.hotel_services?.find(
      service => service.id === id
    )?.sub_properties;
    if (spIdList && spIdList?.length > 0) {
      let spList: any = [];
      spIdList.forEach(spId => {
        spList.push(spData?.getSubProperties?.find(sp => sp?.id === spId));
      });
      setSubProperties(spList);
    }
  }, [
    hotelDetailsQuery,
    id,
    propertyDetails?.getPropertyByDomain?.id,
    propertyMeta?.getMeta?.hotel_services,
    spData?.getSubProperties,
    subPropertiesQuery
  ]);
  return {
    loading: spLoading || loadingProperty || loadingMeta,
    subProperties: subProperties,
    hotelServID: id,
    showChatIcon: propertyDetails?.getPropertyByDomain?.show_talk_to_us
  };
};

export default useSubProperty;
